import React, { useContext } from "react";
import GeneralHelmet from "components/molecules/GeneralHelmet/GeneralHelmet";
import SlicesZone from "modules/Prismic/SlicesZone";
import RichText from "components/atoms/RichText/RichText";
import { Link } from "react-router-dom";
import resolveUrl from "components/helpers/resolveUrl";
import { LayoutContext } from "layout/GenericLayout";

const Portfolio = ({ data, navsData }) => {
  const navData = navsData.portfolio_nav;
  const { slices, meta } = data;
  const { routes, pathname } = useContext(LayoutContext);

  return (
    <>
      <GeneralHelmet meta={meta} />
      <div className="page-content">
        <div className="portfolio">
          <div className="portfolio__container">
            <div className="portfolio__menu">
              <div className="portfolio__menu__ctn">
                <h2 className="portfolio__menu__title">PORTFOLIO</h2>
                <nav className="portfolio__menu__nav">
                  {navData.map((item, key) => {
                    const url = resolveUrl(item.link, routes);
                    const cleanPath = pathname.replace(/\/+$/, "");
                    return (
                      <div
                        key={key}
                        className={`portfolio__menu__nav__item${
                          cleanPath === url ? " active" : ""
                        }`}
                      >
                        <Link to={url}>
                          <RichText type="text" content={item.label} />
                        </Link>
                      </div>
                    );
                  })}
                </nav>
              </div>
            </div>
            <div className="portfolio__images">
              <SlicesZone slices={slices} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
